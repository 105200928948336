//import {lazy} from 'react'
import demoRouter from 'src/pages/demo/router'
export const routes: Array<Troutes> = [...demoRouter]

export type Troutes = {
  path: string
  component?: any
  routes?: Array<Troutes>
  exact?: boolean
}
