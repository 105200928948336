import http from 'src/helpers/http'
import config from 'src/configs'
export const langStore = () => {
  const $l: any = {}
  return {
    $l,
    country: '',
    async getLang(mod: string, lang: string) {
      const language = config.langMap[lang] || config.langMap.default
      const res = await http.get(
        `https://multi-lang.zbisq.com/multiLangBig/marki_international_mobile/${mod}/${language}.json`,
      )
      this.$l = res.data
    },
  }
}

export type langStoreType = ReturnType<typeof langStore>
