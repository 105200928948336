import React from 'react'
import RouterComp from 'src/components/common/RouterComp'
import config from 'src/configs'
import Vconsole from 'vconsole'

if (config.env === 'test') {
  try {
    new Vconsole()
  } catch (e) {
    console.error(e)
  }
}
const App = () => <RouterComp />
export default App
