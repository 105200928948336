import './YYWKWebViewAPI'
declare global {
  interface Window {
    nativeHost: any
    YYApiCore: any
  }
}
window.nativeHost = window.nativeHost || {}
const nativeHost = window.nativeHost || {}

export function invite(name: string, teamId: string) {
  return nativeHost.invite ? nativeHost.invite(name, parseInt(teamId)) : {}
}

export function getUid() {
  return (nativeHost.getUid && nativeHost.getUid()) || ''
}

export function getUserName() {
  return (nativeHost.getUserName && nativeHost.getUserName()) || ''
}

export function getToken(): Promise<string> {
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  if (isiOS) {
    return new Promise((res, rej) => {
      try {
        window.YYApiCore.invokeClientMethod('data', 'getOTP', null, (value: string) => {
          console.log('token', value)
          res(value)
        })
      } catch (e) {
        console.error(e)
        rej(e)
      }
    })
  } else {
    const token = (nativeHost.getOTP && nativeHost.getOTP()) || ''
    console.log(token)
    return Promise.resolve(token)
  }
}
