export default {
  mode: 'prod',
  inviteApi: 'https://marki.biusq.com',
  api: 'https://tars.biusq.com',
  markiApi: 'https://api.markicam.com',
  markiPath: ['/xsvr/share/query'],
  langMap: {
    default: 'en',
    // zh: 'zh-hans',
    en: 'en',
    'zh-hant': 'zh-hant',
    ms: 'ms',
    in: 'id',
  },
}
