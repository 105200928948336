import {langStore, langStoreType} from 'src/stores/lang'
import {inviteStore, inviteStoreType} from 'src/stores/invite'

const stores: {[key: string]: (source: JSONObject) => any} = {
  langStore,
  inviteStore,
}
export type TconfigStore = {
  langStore: langStoreType
  inviteStore: inviteStoreType
}
export default stores
