import React, {Suspense, useEffect} from 'react'
import {Route, BrowserRouter as Router, Switch, useLocation} from 'react-router-dom'
import pageview from 'src/configs/pageview'
import LoadingComp from 'src/components/common/LoadingComp'
import P404Comp from 'src/components/common/P404Comp'
import {routes, Troutes} from 'src/configs/router'
function usePageViews() {
  const location = useLocation()
  useEffect(() => {
    pageview(location)
  }, [location])
}

const RoutersComp = () => routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)

const SwitchMainRouter = () => {
  usePageViews()
  return (
    <Switch>
      {RoutersComp()}
      <Route>
        <P404Comp />
      </Route>
    </Switch>
  )
}

const RouterComp = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingComp />}>
        <SwitchMainRouter />
      </Suspense>
    </Router>
  )
}
export default RouterComp

const RouteWithSubRoutes = (route: Troutes) =>
  (route.component && (
    <Route path={route.path} render={props => <route.component {...props} routes={route.routes} />} />
  )) || <SwitchRouter routes={route.routes} />

export const SwitchRouter = ({routes}: {routes?: Array<Troutes>}) => {
  return (
    <Switch>
      {routes && routes.length > 0 && routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
    </Switch>
  )
}
