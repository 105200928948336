export default {
  mode: 'dev',
  inviteApi: 'http://www.yapi-efox.duowan.com/mock/334',
  //api: 'https://tars.biusq.com',
  //api: 'http://test-tars.biusq.com',
  api: 'http://www.yapi-efox.duowan.com/mock/334',
  // markiApi: 'https://test-api.markicam.com/',
  // markiPath: ['/xsvr/share/query'],
  langMap: {
    default: 'en',
    // zh: 'zh-hans',
    en: 'en',
    'zh-hant': 'zh-hant',
    ms: 'ms',
    in: 'id',
  },
}
