import {lazy} from 'react'

export default [
  {
    path: '/',
    exact: true, //是否首页
    component: lazy(() => import('src/pages/invite')),
  },
  {
    path: '/admin',
    component: lazy(() => import('src/pages/admin')),
  },
  {
    path: '/organize',
    component: lazy(() => import('src/pages/organize')),
  },
  {
    path: '/map',
    component: lazy(() => import('src/pages/map')),
  },
  {
    path: '/about',
    component: lazy(() => import('src/pages/demo/about')),
  },
  {
    path: '/info',
    component: lazy(() => import('src/pages/info')),
  },
  {
    path: '/user',
    component: lazy(() => import('src/pages/user')),
  },
  {
    path: '/team',
    component: lazy(() => import('src/pages/team')),
  },
  {
    path: '/share/group',
    component: lazy(() => import('src/pages/share/group')),
  },
  {
    path: '/share/team',
    component: lazy(() => import('src/pages/share/all')),
  },
  {
    path: '/share/daily',
    component: lazy(() => import('src/pages/share/daily')),
  },
]
