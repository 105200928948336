import axios from 'axios'

const http = axios.create({
  // baseURL: conf.inviteApi,
  // withCredentials: true,
})

http.interceptors.request.use(
  req => {
    return req
  },
  error => {
    return Promise.reject(error)
  },
)

http.interceptors.response.use(
  response => {
    return response.data
    /* if (response.data.code === 0 || response.data) {
      return response.data.data || response.data
    } else {
      return response.data || response
    } */
  },
  error => {
    console.error('error', error)
    return Promise.reject(error)
  },
)
export default http
