import http from 'src/helpers/http'
import {getUid} from 'src/helpers/native'
import conf from 'src/configs'
export const inviteStore = () => {
  return {
    $l: {},
    teamList: [],
    revenue: [],
    marqueeList: [
      '【李众恺】成功邀请【杨洋】加入团队获得奖励10元',
      '【王清峰】成功邀请【李浩】加入团队获得奖励10元',
      '【余俊霖】成功邀请【杨勇】加入团队获得奖励10元',
      '【李政勇】成功邀请【李浩】加入团队获得奖励10元',
      '【孙正】成功邀请【白涵宇】加入团队获得奖励10元',
      '【方思】成功邀请【韩旭】加入团队获得奖励10元',
      '【陈浩】成功邀请【赵楠】加入团队获得奖励10元',
      '【吴学工】成功邀请【李政】加入团队获得奖励10元',
      '【朱梦华】成功邀请【李俊】加入团队获得奖励10元',
      '【徐强】成功邀请【王成杰】加入团队获得奖励10元',
      '【周涛】成功邀请【王志国】加入团队获得奖励10元',
      '【赵志辉】成功邀请【陈建波】加入团队获得奖励10元',
      '【张合】成功邀请【唐静】加入团队获得奖励10元',
      '【马玲】成功邀请【张伟】加入团队获得奖励10元',
      '【郭一伟】成功邀请【郑丽珊】加入团队获得奖励10元',
      '【王自浩】成功邀请【吴鹏超】加入团队获得奖励10元',
      '【候战】成功邀请【李生】加入团队获得奖励10元',
      '【李俊仁】成功邀请【杜瑞】加入团队获得奖励10元',
      '【郭学力】成功邀请【王雪辉】加入团队获得奖励10元',
      '【李俊华】成功邀请【张穗可】加入团队获得奖励10元',
      '【张发树】成功邀请【李恩】加入团队获得奖励10元',
      '【吕魁元】成功邀请【孙明】加入团队获得奖励10元',
      '【王博】成功邀请【方思】加入团队获得奖励10元',
      '【黄文佳】成功邀请【周亚楠】加入团队获得奖励10元',
      '【王彦皓】成功邀请【刘忠】加入团队获得奖励10元',
      '【林康】成功邀请【胡兵】加入团队获得奖励10元',
      '【高斌】成功邀请【张家明】加入团队获得奖励10元',
      '【李伟】成功邀请【宋玉】加入团队获得奖励10元',
      '【张伟利】成功邀请【姜阳】加入团队获得奖励10元',
      '【张世文】成功邀请【黄杰】加入团队获得奖励10元',
      '【李兴】成功邀请【薛仁辉】加入团队获得奖励10元',
      '【王华】成功邀请【刘欢】加入团队获得奖励10元',
      '【张秀芬】成功邀请【段学成】加入团队获得奖励10元',
      '【赵小丹】成功邀请【程辉】加入团队获得奖励10元',
      '【张志远】成功邀请【卢成辉】加入团队获得奖励10元',
      '【马云吉】成功邀请【吴洋】加入团队获得奖励10元',
      '【李亮】成功邀请【温鑫】加入团队获得奖励10元',
      '【郭猛】成功邀请【李涛】加入团队获得奖励10元',
      '【郭胜利】成功邀请【赵城阳】加入团队获得奖励10元',
      '【刘凯】成功邀请【孙志兵】加入团队获得奖励10元',
    ],
    revenueInfo: {},
    async getMyTeamList() {
      const uid = getUid()
      const res: any = await http.get(`${conf.inviteApi}/h5/getMyTeamList`, {params: {uid}})
      this.teamList = res.team_list
      console.log(res)
    },
    async getMyRevenue() {
      const uid = getUid()
      const res: any = await http.get(`${conf.inviteApi}/h5/getMyRevenue`, {params: {uid}})
      console.log(res)
      this.revenue = res.data.list
      this.revenueInfo = res.data
    },
  }
}

export type inviteStoreType = ReturnType<typeof inviteStore>
