import React from 'react'
import ReactDOM from 'react-dom'
import 'mobx-react-lite/batchingForReactDom'
import {StoreProvider} from 'src/stores'
import * as serviceWorker from './serviceWorker'
import './index.scss'
import App from './App'

ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById('root'),
)

// register worker for js cache
serviceWorker.unregister()
